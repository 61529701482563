import React from "react"
import { Link } from "gatsby"

import { withArticle } from "./withArticle"
import {
  Wrapper,
  BreadCrumb,
  Crumb,
  ImageWrapper,
  Details,
  Divider,
  // Title,
  PublishedAt,
} from "./ArticleStyles"
import { Image } from "../Image/Image"
import { Sections } from "../Sections/Sections"
import { Author } from "./Author/Author"
import { FeaturedArticles } from "./FeaturedArticles/FeaturedArticles"

export const Article = withArticle(
  ({
    title,
    author,
    publishedAt,
    image,
    content,
    additionalArticlesTitle,
    url,
    settingShowRelated,
    related,
  }) => (
    <>
      <Wrapper>
        <BreadCrumb>
          <Crumb as={Link} to={url}>
            {additionalArticlesTitle}
          </Crumb>
          &nbsp;/&nbsp;
          <Crumb>{title}</Crumb>
        </BreadCrumb>
        <ImageWrapper>
          <Image image={image} ratio={"16/5"} />
        </ImageWrapper>
        {typeof author !== "undefined" || publishedAt?.length > 0 ? (
          <>
            <Details>
              {typeof author !== "undefined" && <Author author={author} />}
              {publishedAt?.length > 0 && (
                <PublishedAt>{publishedAt}</PublishedAt>
              )}
            </Details>
            <Divider />
          </>
        ) : null}
      </Wrapper>
      <Sections page={{ content }} />
      {settingShowRelated ? <FeaturedArticles related={related} /> : null}
    </>
  ),
)
