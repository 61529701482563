import React from "react"

import { useImage } from "../../../hooks/useImage"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withAuthor =
  Component =>
  ({ name = "Author", author }) => {
    const { getGatsbyImage } = useImage()

    const {
      template: {
        article: { additionalAuthorText },
      },
    } = getAdditionalText()

    const { image: originalImage, name: authorName } = author
    const image = getGatsbyImage(originalImage, { maxWidth: 60 })

    Component.displayName = name
    return (
      <Component
        name={authorName}
        image={image}
        additionalAuthorText={additionalAuthorText}
      />
    )
  }
