import tw from "twin.macro"

export const Wrapper = tw.div`flex-1 flex flex-row mb-1-6 md:mb-0`

export const ImageWrapper = tw.div`border border-white rounded-full max-w-6 w-full mr-1-8`

export const Content = tw.div``

export const Subtitle = tw.p`text-14 leading-1.71 text-grey mb-0-1`

export const Title = tw.p`text-16 leading-1.62`
