import React from "react"

import { withFeaturedSlider } from "./withFeaturedSlider"
import { Wrapper, Title } from "./FeaturedSliderStyles"
import { Prev, Next } from "../SliderStyles"
import { Slider, Slide } from "../Slider"
import { Icon } from "../../Icon/Icon"

export const FeaturedSlider = withFeaturedSlider(
  ({ id, title, items, Card, layout, className }) => (
    <Wrapper>
      <Title>{title}</Title>
      <Slider
        className={className}
        layout={layout}
        prevEl={`#${id}-prev`}
        nextEl={`#${id}-next`}
      >
        {items?.map((item, index) => (
          <Slide key={`${item.title}+${index}`}>
            <Card item={item} />
          </Slide>
        ))}
      </Slider>
      <Prev id={`${id}-prev`}>
        <Icon name={"arrowLeft"} />
      </Prev>
      <Next id={`${id}-next`}>
        <Icon name={"arrow"} />
      </Next>
    </Wrapper>
  ),
)
