import React from "react"

import { withFeaturedArticles } from "./withFeatureArticles"
import { FeaturedArticlesWrapper, Divider } from "./FeaturedArticlesStyle"
import { Card } from "../../Articles/Card/Card"
import { FeaturedSlider } from "../../Slider/FeaturedSlider/FeaturedSlider"
import { StyledContainer } from "../../Styled/Container"

export const FeaturedArticles = withFeaturedArticles(({ title, articles }) => (
  <>
    <StyledContainer>
      <Divider />
    </StyledContainer>
    <FeaturedArticlesWrapper>
      <FeaturedSlider
        id={"article"}
        title={title}
        items={articles}
        Card={Card}
        layout={"article"}
      />
    </FeaturedArticlesWrapper>
  </>
))
