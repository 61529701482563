import tw, { styled } from "twin.macro"
import { H6 } from "../../Styled/Text"

export const Wrapper = tw.div`
  relative
`

export const Title = styled(H6)`
  ${tw`mb-2-4 md:mb-4-2`}
`
