import tw, { styled } from "twin.macro"
import { StyledContainer } from "../../Styled/Container"

export const FeaturedArticlesWrapper = styled(StyledContainer)`
  ${tw`my-4 md:my-8`}
`

export const Divider = tw.hr`
  text-navy text-opacity-25
`
