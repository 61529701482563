import React from "react"
import { graphql } from "gatsby"

import { Article as Template } from "../components/Article/Article"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query($id: String!) {
    article: sanityArticle(_id: { eq: $id }) {
      ...GatsbyArticleFragment
      related: _rawRelated(resolveReferences: { maxDepth: 4 })
    }
    template: sanityTemplateArticle {
      settingShowRelated
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {

  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("article_page")
  
  return <Template {...props} {...data} />
}
export default Component
