import React from "react"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

import { useImage } from "../../hooks/useImage"
import { useApp } from "../../hooks/useApp"
import { getAdditionalText } from "../../utils/getAdditionalText"

dayjs.extend(LocalizedFormat)

export const withArticle =
  Component =>
  ({ name = "Article", article, template }) => {
    const {
      config: {
        settings: { routes },
      },
    } = useApp()

    const { getGatsbyImage } = useImage()

    const {
      attributes,
      content,
      image: originalImage,
      title,
      related,
    } = article || {}

    const { settingShowRelated } = template || {}

    const { author, publishedAt } = attributes || {}

    const {
      template: {
        article: { additionalArticlesTitle },
      },
    } = getAdditionalText()

    const formattedPublishedAt = publishedAt
      ? dayjs(publishedAt).format("LL")
      : null
    const image = getGatsbyImage(originalImage)

    const url = routes.ARTICLE

    Component.displayName = name
    return (
      <Component
        author={author}
        publishedAt={formattedPublishedAt}
        content={content}
        image={image}
        title={title}
        additionalArticlesTitle={additionalArticlesTitle}
        url={url}
        settingShowRelated={settingShowRelated}
        related={related}
      />
    )
  }
