import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)``

export const BreadCrumb = tw.div`
  mt-3-2 md:mt-1-6 mb-1-6 inline-block text-navy text-opacity-60
`

export const Crumb = tw.p`
  text-14 leading-1.71 inline
`

export const ImageWrapper = tw.div`
  w-full mb-1-6 md:mb-4-8
`

export const Details = tw.div`
  max-w-86 mx-auto pb-3-2 md:pb-4 flex flex-col justify-start md:flex-row md:justify-between md:items-center
`

export const Divider = tw.hr`
  mb-3 md:mb-4 text-navy text-opacity-20
`

export const Title = tw.h1`
  text-20 md:text-35 leading-1.25 md:leading-1.28 tracking-2 md:tracking-3 font-bold mb-3 md:mb-5-6 uppercase
`

export const PublishedAt = tw.p`
  text-14 leading-1.78 text-grey
`
