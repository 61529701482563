import React from "react"

export const withFeaturedSlider = Component => ({
  name = "FeaturedSlider",
  title,
  id,
  items,
  Card,
  layout,
  className = "",
}) => {
  Component.displayName = name
  return (
    <Component
      id={id}
      title={title}
      items={items}
      Card={Card}
      layout={layout}
      className={className}
    />
  )
}
