import React from "react"

import { useFeaturedArticles } from "../../../hooks/useArticles"
import { useApp } from "../../../hooks/useApp"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withFeaturedArticles = Component => ({
  related,
  name = "FeaturedArticles",
}) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const {
    template: {
      article: { additionalContinueText },
    },
  } = getAdditionalText()

  const { articles } = useFeaturedArticles()

  const items = articles?.edges?.map(({ node }) => node)
  const url = routes.ARTICLE

  Component.displayName = name
  return (
    <Component
      title={additionalContinueText}
      articles={related?.length > 0 ? related : items}
      url={url}
    />
  )
}
