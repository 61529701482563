import React from "react"

import { withAuthor } from "./withAuthor"
import { Wrapper, ImageWrapper, Content, Subtitle, Title } from "./AuthorStyles"
import { Image } from "../../Image/Image"

export const Author = withAuthor(({ image, name, additionalAuthorText }) => (
  <Wrapper>
    {image && (
      <ImageWrapper>
        <Image image={image} />
      </ImageWrapper>
    )}
    <Content>
      <Subtitle>{additionalAuthorText}</Subtitle>
      <Title>{name}</Title>
    </Content>
  </Wrapper>
))
